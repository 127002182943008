function App() {
  return (
    <div className="wrapper" style={{ backgroundImage: 'url("./bg.jpg")' }}>
      <h1>
        Strona w przygotowaniu
      </h1>

      <h3>
        Jeśli masz pytania zapraszam do kontaktu <br/> <a href="tel:+48506627605">506-627-605</a>
      </h3>
    </div>
  );
}

export default App;
