import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

WebFont.load({
  google: {
    families: ['Libre Baskerville:700', 'Montserrat:400']
  }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
